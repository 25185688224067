import React, { useState, useEffect } from 'react'
import {
  Container,
  NotiStyle,
  BoxTitle,
  CustomDrawer,
  CustomDivider,
  CustomInputDrawer,
  BtSubmit,
  CustomheadTime,
  StyleDropdown,
  CustomPopover,
  LabelText,
  CustomUploadfile,
  ButtonOpenMap,
  LabelTexthead,
  ClearableTextArea,
  BoxContentDetail,
  BoxSeeDetail,
  WrapBoxComplaint,
  WrapMap,
  ButtonBack,
  DetailLocation,
  BoxBtnInMap,
} from './FormCreateComplaint'

import { postData, putData, getData, getList } from 'core/action/collection'

import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import InputComponent from 'components/input/Input'
import { mockUserData, defaultValues, Schema } from './container'
import iconAdd from 'assets/images/svg/icon-add-org.svg'
import iconTime from 'assets/images/svg/icon-time-head.svg'
import iconInfo from 'assets/images/svg/icon-info.svg'
import iconpinLocation from 'assets/images/svg/icon-pin-location.svg'
import iconarrowright from 'assets/images/svg/icon-arrow-right-org.svg'
// import iconclose from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-complain.svg'
// import IconAllowClear from 'assets/images/svg/icon-add-org.svg'
import iconHome from 'assets/images/png/icon-homepage.png'
import support from 'assets/images/png/logo-support.png'
import lineqrcode from 'assets/images/png/lineqrcode-add-prod.png'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { authentication, masterdata, complaint } from 'core/schemas'
import { httpClient } from 'core/action/httpClient'
import axios from 'axios'
import UploadFile from 'components/Inputs/UploadFile'
import { ReactComponent as IconClearinput } from 'assets/images/svg/icon-clear-orange.svg'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import TestIcon from 'assets/images/svg/tset-icon-category.svg'
import { ReactComponent as ButtonCloseSeeDeail } from 'assets/images/svg/button-close-see-detail.svg'
import { disable } from 'ol/rotationconstraint'
import BoxComplaintMe from 'components/BoxComplaintMe/BoxComplaintMe'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import liff from '@line/liff'
import { Button, Modal } from 'antd'
import useGetAddress from 'core/hooks/useGetAddress'
import { conversDate } from 'core/utils/DateTimeFormat'
import moment from 'moment'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import { ReactComponent as CloseModelMap } from '../image/svg/CloseModelMap.svg'
import Viewmap from './MapSelect'
import BeforeIcon from '../image/png/Before Success.png'

const Index = () => {
  const navigate = useNavigate()

  const [formattedDate, setFormattedDate] = useState('')
  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [categories, setCategories] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')
  const [cover, setCover] = useState([])
  const [locationme, setlocationme] = useState({})
  const [submit, setsubmit] = useState({})
  const [categoryId, setCategoryId] = useState('')
  const [subCategories, setSubCategory] = useState([])
  const [lineId, setLineId] = useState('')

  const [openDrawerSuccess, setOpenDrawerSuccess] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [clickedLatLng, setClickedLatLng] = React.useState(null)
  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })
  const [isModalOpenError, setIsModalOpenError] = useState(false)
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 })
  const [zoomMap, setZoomMap] = useState(14)
  const [loaddingCraete, setloaddingCraete] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const [selectCategory, setSelectCategory] = useState('')

  const address = useGetAddress(clickedLatLng ? clickedLatLng : meLocation)

  let dataNow = conversDate(moment().unix())
  let arrArear = []
  NakhonRatchasima?.features[0]?.geometry?.coordinates[0]?.map((item, index) => {
    arrArear.push({ lat: item[1], lng: item[0] })
  })

  const [defaultValueFromList, setDefaultValueFromList] = React.useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    groupuser: '',
    address: '',
    province: '',
    district: '',
    sub_districte: '',
    postcode: '',
    categories: '',
    name_complain: '',
    description_complain: '',
  })

  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            setMeLocation({ lat: latitude, lng: longitude })
            setCenterMap({ lat: latitude, lng: longitude })
          },
          error => {
            console.error('Error getting location:', error.message)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }
    fetchUserLocation() // Call the function when the component mounts
  }, [])

  useEffect(() => {
    getCategories()
    // getProfileLine()
  }, [])

  useEffect(() => {
    categoryId !== '' && getSubCategory()
  }, [categoryId])

  const checkimg = cover.length > 0 ? true : false
  const checkmap = Object.keys(locationme).length === 0 || locationme == {} ? false : true
  const isValidForm = formik.isValid && formik.dirty && !disableBtn && checkimg

  const getCategories = async () => {
    const res = await getData({
      schema: `${masterdata}/all/categories`,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)
    if (res) {
      const newData = res.data
      setCategories(newData)
    }
  }

  const getSubCategory = async () => {
    const res = await getData({
      schema: `${masterdata}/all/subcategories/` + categoryId,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setSubCategory(newData)
    }
  }

  const optionsCategories = categories?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    icon_url: items?.icon_url,
    key: index,
  }))

  const optionsSubCategories = subCategories?.map((items, index) => ({
    value: items?.ID,
    label: items?.name,
    key: index,
  }))

  const DatetimeNowThai = () => {
    const currentDate = new Date()

    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      eraDisplay: 'narrow',
    }

    const thaiLocale = 'th-TH'

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat(thaiLocale, options).format(currentDate)
    setFormattedDate(formattedDate)
  }

  useEffect(() => {
    DatetimeNowThai()
  }, [])

  const getProfileLine = async () => {
    await liff
      .init({ liffId: '2000994746-0wglpjzY' })
      .then(() => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(profile => {
              setLineId(profile.userId)
            })
            .catch(err => console.error(err))
        } else {
          liff.login()
        }
      })
      .catch(err => {
        console.log('err', err)
        localStorage.clear()
        navigate('/login')
      })
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const uniqueValidate = value => {
    if (value?.mobile_number?.length < 12) {
      setTimeout(() => {
        formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
      }, 100)
    }
    if (formik.values.mobile_number.length < 12) {
      formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
    }
  }

  const onChangeProvince = () => {
    formik.setFieldValue('district_id', '')
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getDistrict()
    // getSubDistrict()
  }

  const handleDrawerOptionClick = (value, fieldFormik) => {
    if (fieldFormik === 'group_id') {
      formik.setFieldValue('group_id', value)
    }
    if (fieldFormik === 'province_id') {
      formik.setFieldValue('province_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('district_id', '')
      formik.setFieldValue('sub_district_id', '')
      setProvinceId(value)
    }
    if (fieldFormik === 'district_id') {
      formik.setFieldValue('district_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('sub_district_id', '')
      setDistrictId(value)
    }
    if (fieldFormik === 'sub_district_id') {
      formik.setFieldValue('sub_district_id', value)
      setSubDistrictId(value)
    }
  }

  const onChangeDistrict = async value => {
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getSubDistrict()
  }

  const handleUpdateStatusFile = async code => {
    cover.map(item => {
      const body = {
        status: 'active',
        url: item?.img,
      }
      axios
        .put(process.env.REACT_APP_MEDIA + '/medias', body)
        .then(res => {})
        .catch(err => {
          setloaddingCraete(false)
          console.log('err', err)
        })
    })
    setTimeout(() => {
      setloaddingCraete(false)
      setOpenDrawerSuccess(true)
    }, 1000)
  }

  const handlerSubmit = async () => {
    setloaddingCraete(true)
    const phone = formik.values.mobile_number.replace(/-/g, '')

    const {
      first_name,
      last_name,
      mobile_number,
      group_id,
      province_id,
      district_id,
      sub_district_id,
      postcode,
      category_id,
      name_complain,
      description_complain,
      sub_category_id,
    } = formik.values

    const dataurl = []

    cover.map(item => {
      dataurl.push('https://' + item.urlkey)
    })

    const tmpValues = {
      address: address[2]?.formatted_address,
      category_id: category_id,
      channel: 'webboard',
      complaint_name: name_complain,
      coordinates: {
        latitude: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
        longitude: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
      },
      description: description_complain,
      line_id: 's',
      media_url: dataurl,
      mobile_number: phone,
      name: {
        first_name: first_name,
        last_name: last_name,
      },
      org_id: '19118133238380',
      status_data: [
        {
          description: 'รับเรื่อง',
          name: 'received',
          staff_by: null,
        },
      ],
      sub_category_id: sub_category_id,
    }
    const res = await postData(`${complaint}/guest/complaint`, tmpValues)
    if (res.code === 201) {
      handleUpdateStatusFile(res?.code)
    }
  }

  const checkArear = val => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
    })
    const pointToCheck = new window.google.maps.LatLng(val?.lat, val?.lng)
    if (
      window.google.maps.geometry &&
      window.google.maps.geometry.poly &&
      window.google.maps.geometry.poly.containsLocation
    ) {
      const isInside = window.google.maps.geometry.poly.containsLocation(pointToCheck, bermudaTriangle)
      return isInside
    } else {
      console.error("Google Maps 'containsLocation' method is not available.")
      return false // Return false assuming the location is outside if the check cannot be performed
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleOk = () => {
    if (clickedLatLng?.lat || clickedLatLng?.lng) {
      setIsModalOpen(false)
      // handleChangeForm()
    } else {
      if (checkArear(meLocation)) {
        setClickedLatLng({
          lat: meLocation?.lat,
          lng: meLocation?.lng,
        })
        setIsModalOpen(false)
        // handleChangeForm()
      } else {
        setIsModalOpenError(true)
      }
    }
  }

  const handleClickMap = event => {
    if (isModalOpen) {
      const cord = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }

      if (checkArear(cord)) {
        setAutocompleteValue('')
        setSearchValue('')
        setClickedLatLng({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setCenterMap({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setZoomMap(14)
      } else {
        setIsModalOpenError(true)
      }
    }
  }

  return (
    <>
      <div style={{ padding: '0 7%' }}>
        <div style={{ height: '3.5em', position: 'relative', marginTop: '40px' }}>
          {/* <div className="close-icon" onClick={() => oncloseComplain()}>
            <IconClear />
          </div> */}
          <div className="title" style={{ display: 'flex' }}>
            <ButtonBack
              shape="circle"
              icon={<IconArrowLeft />}
              onClick={() => navigate('/')}
              style={{ marginRight: '8px' }}
            />
            <h1>แจ้งเรื่องร้องเรียน</h1>
          </div>
        </div>

        {formik.values && (
          <>
            <Container>
              <form onSubmit={formik.handleSubmit}>
                <div style={{ padding: '25px 0px 20px 0px' }}>
                  <CustomheadTime>
                    <div>
                      <img src={iconTime} />
                    </div>
                    <div>เวลาแจ้งเรื่อง :</div>
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: '400',
                      }}
                    >
                      {' '}
                      {formattedDate}{' '}
                    </div>
                  </CustomheadTime>

                  <div className="row-data">
                    <div className="col-1">
                      <InputComponent
                        label="ชื่อ"
                        name="first_name"
                        type="text"
                        required={true}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={formik?.values?.first_name}
                        form={formik}
                        status={formik.status}
                        maxLength={255}
                        requiredPadding={1}
                        placeholder="โปรดระบุชื่อของผู้ร้องเรียน"
                        prefix={
                          <IconClearinput
                            style={{
                              display: formik?.values?.first_name.length > 0 ? '' : 'none',
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '16px',
                              zIndex: 1,
                            }}
                            onClick={async () => {
                              formik.setFieldValue('first_name', '')
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="row-data">
                    <div className="col-1">
                      <InputComponent
                        label="นามสกุล"
                        name="last_name"
                        type="text"
                        required={true}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={formik?.values?.last_name}
                        form={formik}
                        status={formik.status}
                        maxLength={255}
                        requiredPadding={1}
                        placeholder="โปรดระบุนามสกุลของผู้ร้องเรียน"
                        prefix={
                          <IconClearinput
                            style={{
                              display: formik?.values?.last_name.length > 0 ? '' : 'none',
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '16px',
                              zIndex: 1,
                            }}
                            onClick={async () => {
                              formik.setFieldValue('last_name', '')
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="row-data">
                    <div className="col-1">
                      <InputComponent
                        label="เบอร์โทรศัพท์"
                        name="mobile_number"
                        placeholder="0XX-XXX-XXXX"
                        type="text"
                        required={true}
                        errors={formik.errors}
                        touched={formik.touched}
                        form={formik}
                        status={formik.status}
                        value={formik?.values?.mobile_number}
                        requiredPadding={1}
                        maxLength={12}
                        onChange={onPhoneNumberChange}
                        inputMode="tel"
                        uniqueValidate={uniqueValidate}
                        prefix={
                          <IconClearinput
                            style={{
                              display: formik?.values?.mobile_number.length > 0 ? '' : 'none',
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '16px',
                              zIndex: 1,
                            }}
                            onClick={async () => {
                              formik.setFieldValue('mobile_number', '')
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="row-data">
                    <div className="col-1">
                      <LabelTexthead>
                        <p className="text-head">ประเภทเรื่องร้องเรียน</p>
                        <div className="red-star" style={{ paddingLeft: '2px' }}>
                          *
                        </div>
                      </LabelTexthead>

                      <StyleDropdown>
                        <DropdownEditProfile
                          title="ประเภทเรื่องร้องเรียน"
                          placeholder="กรุณาเลือก"
                          showSearch={false}
                          required={true}
                          className="big-input"
                          onChange={value => {
                            formik.setFieldValue('category_id', value)
                            setCategoryId(value)
                            formik.setFieldValue('sub_category_id', '')
                          }}
                          options={optionsCategories}
                          value={formik?.values?.category_id}
                          onDrawerOptionClick={handleDrawerOptionClick}
                          fieldFormik="category_id"
                        />
                      </StyleDropdown>
                    </div>
                  </div>
                  <div className="row-data">
                    <div className="col-1">
                      <LabelTexthead>
                        <p className="text-head">ประเภทย่อย</p>
                        <div className="red-star" style={{ paddingLeft: '2px' }}>
                          *
                        </div>
                      </LabelTexthead>

                      <StyleDropdown>
                        <DropdownEditProfile
                          title="ประเภทย่อย"
                          placeholder="กรุณาเลือก"
                          showSearch={false}
                          required={true}
                          className="big-input"
                          onChange={value => {
                            setSelectCategory(value)
                            formik.setFieldValue('sub_category_id', value)
                          }}
                          options={optionsSubCategories}
                          value={formik?.values?.sub_category_id}
                          onDrawerOptionClick={handleDrawerOptionClick}
                          fieldFormik="category_id"
                        />
                      </StyleDropdown>
                    </div>
                  </div>
                  {/* <div className="row-data">
                    <div className="col-1">
                      <InputComponent
                        label="เรื่องร้องเรียน"
                        name="name_complain"
                        placeholder="โปรดระบุเรื่องร้องเรียนสั้นๆ เช่น “น้ำไม่ไหล”,“ต้นไม้เกี่ยวพันสายไฟ”"
                        type="text"
                        required={true}
                        value={formik.values.name_complain}
                        errors={formik.errors}
                        touched={formik.touched}
                        form={formik}
                        status={formik.status}
                        maxLength={35}
                        requiredPadding={1}
                        prefix={
                          <IconClearinput
                            style={{
                              display: formik?.values?.name_complain.length > 0 ? '' : 'none',
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '16px',
                              zIndex: 1,
                            }}
                            onClick={async () => {
                              formik.setFieldValue('name_complain', '')
                            }}
                          />
                        }
                      />
                    </div>
                  </div> */}
                  <div className="row-data">
                    <div className="col-1">
                      <div style={{ position: 'relative' }}>
                        <InputComponent
                          label="รายละเอียดเพิ่มเติม (ถ้ามี)"
                          type="textarea"
                          name="description_complain"
                          placeholder="ระบุรายละเอียดบริเวณที่เกิดปัญหา หรือรายละเอียดของ
                ปัญหาเพิ่มเติม”"
                          required={false}
                          value={formik.values.description_complain}
                          errors={formik.errors}
                          touched={formik.touched}
                          form={formik}
                          status={formik.status}
                          // maxLength={255}
                          height={120}
                        />
                        <ClearableTextArea
                          style={{ display: formik?.values?.description_complain.length > 0 ? '' : 'none' }}
                        >
                          <IconClearinput
                            onClick={async () => {
                              formik.setFieldValue('description_complain', '')
                            }}
                          />
                        </ClearableTextArea>
                      </div>
                    </div>
                  </div>
                  <div className="row-data">
                    <div className="col-1">
                      <div>
                        <LabelText>อัปโหลดรูปภาพหรือวิดีโอ</LabelText>
                        <span style={{ fontSize: '10px', color: '#001f34' }}>{` (อัปโหลดได้ไม่เกิน 3 ไฟล์)`}</span>
                        <span className="red-star"> * </span>
                        <span className="" style={{ float: 'right' }}>
                          <CustomPopover
                            placement="leftTop"
                            content={
                              <div style={{ marginTop: '6px' }}>
                                <p>ภาพที่อัปโหลดต้องเป็น “ภาพที่เกี่ยวข้องกับเหตุการณ์”</p>
                              </div>
                            }
                          >
                            <img src={iconInfo} style={{ width: 16, height: 16, cursor: 'pointer' }} />
                          </CustomPopover>
                        </span>
                        <CustomUploadfile>
                          <UploadFile
                            accept={{
                              'image/jpeg': [],
                              'image/png': [],
                              'video/mp4': [],
                            }}
                            keys={1}
                            files={cover}
                            isCrop={false}
                            maxFile={3}
                            maxSize={10 * 1024 * 1024}
                            typeUpload={'imagevdo'}
                            text={
                              'ไฟล์ที่อัปโหลดต้องเป็นไฟล์ .jpeg .jpg .png มีขนาดไม่เกิน 10MB ต่อภาพ และ .mp4 มีขนาดไม่เกิน 100MB ต่อวิดีโอ'
                            }
                            onFileRetuen={(files, file) => {
                              let _image = [...files]
                              _image.push(file)
                              setCover(_image)
                            }}
                            onFileRemove={(files, res) => {
                              let _image = [...files]
                              _image = _image.filter((r, i) => i !== res.index)
                              setCover(_image)
                            }}
                          />
                        </CustomUploadfile>
                      </div>
                    </div>
                  </div>
                  <div className="row-data" style={{ margin: '1rem 0rem' }}>
                    <div className="col-1">
                      <div>
                        <div style={{ marginBottom: '10px' }}>สถานที่แจ้งเรื่อง</div>

                        <div>
                          <ButtonOpenMap onClick={setIsModalOpen}>
                            <div className="col-location-1">
                              <img src={iconpinLocation} />
                            </div>
                            <div className="col-location-2">
                              <div>เลือกตำแหน่งที่ตั้ง</div>
                              <DetailLocation text={address[2]?.formatted_address}>
                                {address[2]?.formatted_address}
                              </DetailLocation>
                            </div>
                            <div className="col-location-3">
                              <img src={iconarrowright} alt="arrow" />
                            </div>
                          </ButtonOpenMap>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row-data">
                    <div className="col-1">
                      <BtSubmit onClick={() => handlerSubmit()} disabled={!isValidForm} loading={loaddingCraete}>
                        ส่งเรื่องร้องเรียน
                      </BtSubmit>
                    </div>
                  </div>
                </div>
              </form>
            </Container>
          </>
        )}

        <CustomDrawer placement="right" closable={false} open={openDrawerSuccess} closeIcon={<IconClear />}>
          <div
            style={{
              position: 'relative',
              padding: '160px 64px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              className="title"
              style={{
                fontFamily: 'Prompt',
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              <h1>ส่งเรื่องร้องเรียนสำเร็จ</h1>
            </div>
            <div
              style={{
                marginTop: '80px',
                boxShadow: '0px 2px 10px 0px #F4F4F4',
              }}
            >
              <img src={lineqrcode} style={{ width: '200px', height: '200px' }} />
            </div>

            <div
              style={{
                fontFamily: 'Prompt',
                fontSize: '16px',
                fontWeight: '500',
                marginTop: '16px',
                textAlign: 'center',
              }}
            >
              <div>ท่านสามารถเชื่อมต่อข้อมูลเบอร์โทรศัพท์ผ่านไลน์</div>
              <div>เพื่อรับการแจ้งเตือนสถานะการของเรื่องร้องเรียน</div>
            </div>

            <div
              style={{
                fontFamily: 'Prompt',
                fontSize: '16px',
                fontWeight: '400',
                marginBottom: '80px',
              }}
            >
              ผ่านการสแกน QR Code ด้านบน หรือ LineOA:@Koratcity
            </div>

            <BtSubmit onClick={() => navigate('/')}>กลับสู่หน้าหลัก</BtSubmit>
          </div>
        </CustomDrawer>
      </div>

      <Modal
        open={isModalOpen}
        closeIcon={<CloseModelMap onClick={handleCancel} />}
        className="model-map-create-complant"
        title="เลือกตำแหน่งที่ตั้ง"
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ height: '80vh', width: '90vw', borderRadius: '10px' }}
        style={{ borderRadius: '10px' }}
        centered
      >
        <Viewmap
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          clickedLatLng={clickedLatLng}
          setClickedLatLng={setClickedLatLng}
          meLocation={meLocation}
          address={address[2]?.formatted_address}
          arrArear={arrArear}
          setIsModalOpenError={setIsModalOpenError}
          handleOk={handleOk}
          handleClickMap={handleClickMap}
          checkArear={checkArear}
          centerMap={centerMap}
          setCenterMap={setCenterMap}
          setZoomMap={setZoomMap}
          zoomMap={zoomMap}
          autocompleteValue={autocompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
      </Modal>

      <Modal
        closable={false}
        open={isModalOpenError}
        footer={null}
        className="model-map-create-complant-error"
        centered
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Prompt , sans-serif',
            textAlign: 'center',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '15%',
              }}
            >
              <img src={BeforeIcon} style={{ width: '35px', height: '35px' }} />
            </div>
            <p style={{ fontSize: '20px', margin: 0 }}>ไม่อยู่ในเขตที่รับผิดชอบ</p>
            <p style={{ fontSize: '16px', color: '#C4C4C4', marginBottom: '15%' }}>กรุณาเลือกตำแหน่งใหม่</p>
            <BoxBtnInMap>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{ width: '100px' }}
                  onClick={() => {
                    setIsModalOpenError(false)
                    setloaddingCraete(false)
                  }}
                >
                  ตกลง
                </Button>
              </div>
            </BoxBtnInMap>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Index
