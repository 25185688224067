import React from 'react'
import styled from 'styled-components'
import TestIcon from 'assets/images/svg/tset-icon-category.svg'
import './BoxComplaintMe.css'
import { ReactComponent as IconLocationComMe } from 'assets/images/svg/icon-location-card-complaint-me.svg'
import { ReactComponent as IconClockMe } from 'assets/images/svg/icon-clock-card-complaint-me.svg'
import { ReactComponent as IconCategoryMe } from 'assets/images/svg/icon-category-card-complaint-me.svg'
import { ReactComponent as IconNumberMe } from 'assets/images/svg/icon-number-card-complaint-me.svg'

import {
  WrapContainer,
  Container,
  BoxTitle,
  TextTitle,
  TestStatus,
  WrapBoxContent,
  BoxContentLeft,
  BoxContentRight,
  BoxLocation,
  BoxNumber,
} from './BoxComplaintMe.style'
import { checkStatusText } from './container'

const BoxComplaintMe = props => {
  const { onClick, data, type } = props

  const convertUnixToDateTime = unixTimestamp => {
    const dateObject = new Date(unixTimestamp * 1000)

    const hour = dateObject.getHours().toString().padStart(2, '0')
    const minute = dateObject.getMinutes().toString().padStart(2, '0')
    const day = dateObject.getDate().toString().padStart(2, '0')

    const months = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค']
    const month = months[dateObject.getMonth()]

    const year = (dateObject.getFullYear() + 543).toString() // แปลงเป็น พ.ศ.

    const formattedDate = `${hour}:${minute} น., ${day} ${month} ${year}`

    return formattedDate
  }

  return (
    <>
      <WrapContainer onClick={onClick} type={type}>
        <Container>
          <BoxTitle>
            <TextTitle>
              <div style={{ width: 'auto' }}>
                <img
                  src={data?.type_of_complaint ? data?.type_of_complaint?.icon_url : data?.category?.icon_url}
                  alt="icon-cateogry"
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
              </div>
              <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <span>{data?.title_complaint ? data?.title_complaint : data?.complaint_name}</span>
              </div>
            </TextTitle>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <TestStatus status={data?.status_data[data?.status_data?.length - 1]?.name}>
                {checkStatusText(data?.status_data[data?.status_data?.length - 1]?.name)}
              </TestStatus>
            </div>
          </BoxTitle>
          <WrapBoxContent>
            <BoxContentLeft>
              <BoxLocation>
                <div style={{ marginRight: '1rem' }}>
                  <IconLocationComMe />
                </div>
                <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <div className="title">สถานที่:</div>
                    <div
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <p className="detail">{data?.address ? data?.address : '-'}</p>
                    </div>
                  </div>
                </div>
              </BoxLocation>
              <BoxLocation>
                <div style={{ marginRight: '1rem' }}>
                  <IconClockMe />
                </div>
                <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <div className="title">วันและเวลาแจ้งเรื่อง:</div>
                    <div
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <p className="detail">{convertUnixToDateTime(data?.updated_at)}</p>
                    </div>
                  </div>
                </div>
              </BoxLocation>
            </BoxContentLeft>
            <BoxContentRight>
              <img
                src={
                  data?.media_url?.length > 0
                    ? data?.media_url[0]
                    : 'https://cdn.pixabay.com/photo/2023/12/08/23/46/cat-8438334_1280.jpg'
                }
                alt="img"
              />
            </BoxContentRight>
          </WrapBoxContent>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BoxLocation style={{ marginRight: '1rem' }}>
              <div style={{ marginRight: '1rem' }}>
                <IconCategoryMe />
              </div>
              <div style={{ ooverflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <div>
                  <div className="title">ประเภท:</div>
                  <div
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <p className="detail">
                      {data?.type_of_complaint ? data?.type_of_complaint?.name : data?.category?.name}
                    </p>
                  </div>
                </div>
              </div>
            </BoxLocation>
            <BoxNumber>
              <div style={{ marginRight: '1rem' }}>
                <IconNumberMe />
              </div>
              <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <div>
                  <div className="title">เลขที่:</div>
                  <div
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <p className="detail two-line-ellipsis">{data?.complaint_no}</p>
                  </div>
                </div>
              </div>
            </BoxNumber>
          </div>
        </Container>
      </WrapContainer>
    </>
  )
}

export default BoxComplaintMe

// const WrapContainer = styled.div`
//   padding: 12px;
//   background-color: #fffaf8;
//   box-shadow: 0px 2px 10px 0px rgba(244, 244, 244, 1);
//   margin-right: 10px;
//   border-radius: 24px;
//   margin-bottom: 8px;
// `

// const Container = styled.div`
//   background-color: #ffffff;
//   height: 221px;
//   width: 100%;
//   border-radius: 28px;
//   padding: 24px;
// `

// const BoxTitle = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 1rem;
//   background-color: violet;
// `

// const TextTitle = styled.div`
//   /* display: flex;
//   align-items: center;
//   width: 100%;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   font-family: 'Prompt';
//   font-size: 16px;
//   font-weight: 500;
//   color: #150f0b; */
//   display: flex;
//   align-items: center;
//   /* width: 90%;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   font-family: 'Prompt';
//   font-size: 16px;
//   font-weight: 500;
//   white-space: nowrap;
//   color: #150f0b; */
// `

// const TestStatus = styled.div`
//   width: 100%;
// `
