/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { notification, Divider, Button, Popover, Segmented, Dropdown, Spin, Checkbox } from 'antd'
import { useFormik } from 'formik'
import qs from 'qs'

import {
  Container,
  NotiStyle,
  BoxTitle,
  CustomDrawer,
  CustomDivider,
  CustomInputDrawer,
  BtSubmit,
  CustomheadTime,
  StyleDropdown,
  CustomPopover,
  LabelText,
  CustomUploadfile,
  ButtonOpenMap,
  LabelTexthead,
  ClearableTextArea,
  BoxContentDetail,
  BoxSeeDetail,
  WrapBoxComplaint,
  WrapMap,
  Menucustom,
  CustomCheckBox,
  WrapBoxSearchNotFound,
  BoxResultText,
  BtNewComplaint,
  WrapContent,
  WrapContentResponsive,
  WrapComplaintMeResponsive,
} from './HomePage.style'
import { postData, putData, getData, getList } from 'core/action/collection'

import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import InputComponent from 'components/input/Input'
import { mockUserData, defaultValues, Schema } from './container'
import iconAdd from 'assets/images/svg/icon-add-org.svg'
import iconTime from 'assets/images/svg/icon-time-head.svg'
import iconInfo from 'assets/images/svg/icon-info.svg'
import iconpinLocation from 'assets/images/svg/icon-pin-location.svg'
import iconarrowright from 'assets/images/svg/icon-arrow-right-org.svg'
// import iconclose from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-complain.svg'
// import IconAllowClear from 'assets/images/svg/icon-add-org.svg'
import iconHome from 'assets/images/png/icon-homepage.png'
import support from 'assets/images/png/logo-support.png'
import lineqrcode from 'assets/images/png/lineqrcode.png'
// import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { authentication, masterdata, complaint } from 'core/schemas'
import { httpClient } from 'core/action/httpClient'
import axios from 'axios'
import UploadFile from 'components/Inputs/UploadFile'
import { ReactComponent as IconClearinput } from 'assets/images/svg/icon-clear-orange.svg'
import { ReactComponent as ChatBotButton } from 'assets/images/svg/Chatbot-Button.svg'
import { ReactComponent as EmptySearch } from 'assets/images/svg/EmptySearch.svg'
import { ReactComponent as ArrowSearch } from 'assets/images/svg/arrow-left-search.svg'
import { ReactComponent as VectorDrawer } from 'assets/images/svg/VectorDrawer.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import MapButton from './GoogleMap/Map'
import MapHome from './GoogleMap/MapHome'
import { disable } from 'ol/rotationconstraint'
import BoxComplaintMe from 'components/BoxComplaintMe/BoxComplaintMe'
import styled from 'styled-components'
import IconNoti from 'assets/images/svg/icon-noti.svg'

import BoxSeeMoreDetail from 'components/BoxSeeMoreDetail/BoxSeeMoreDetail'
import UserContext from 'core/contexts/userContext'
import { useSearchContext } from 'core/contexts/useSearch'
import testIconMap from './GoogleMap/image/png/test-icon-in-map.png'
import DrawerSeemoreDetail from './DrawerSeemoreDeatil'
import { io } from 'socket.io-client'
import { useNotificationContext } from 'core/contexts/useNotification'

//http://34.142.244.27:3000

const HomePage = props => {
  const navigate = useNavigate()
  const { meData, getMeData } = useContext(UserContext)
  const token = localStorage.getItem('access_token')
  const { sharedValue, setSharedValue } = useSearchContext()
  const { notiValue } = useNotificationContext()

  //http://34.142.244.27:3000

  const [api, contextHolder] = notification.useNotification()
  const [openDrawerDetailComplaint, setOpenDrawerDetailComplaint] = React.useState(false)
  const [openDrawerSuccess, setOpenDrawerSuccess] = React.useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [defaultValueFromList, setDefaultValueFromList] = React.useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    groupuser: '',
    address: '',
    province: '',
    district: '',
    sub_districte: '',
    postcode: '',
    categories: '',
    name_complain: '',
    description_complain: '',
  })

  const [edit, setEdit] = React.useState(true)
  const [submit, setsubmit] = React.useState({})
  const [formattedDate, setFormattedDate] = useState('')

  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [mapOpen, setMapOpen] = useState()
  const [cover, setCover] = React.useState([])
  const [locationme, setlocationme] = React.useState({})
  const [arrow, setArrow] = useState('Show')
  const [showDetail, setShowDetail] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [eserviceData, setEserviceData] = useState([])
  const [dataMap, setDataMap] = useState([])

  const [dataOnClickCard, setDataOnClickCard] = useState('')
  const [loadingComplaintMe, setLoadingComplaintMe] = useState(true)

  const [checkBoxComplaint, setCheckBoxComplaint] = useState(true)
  const [checkBoxEservice, setCheckBoxEservice] = useState(true)
  const [isResponsive, setIsResponsive] = useState(false)

  const [boxheight, setBoxheight] = useState(1)

  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const checkimg = cover.length > 0 ? true : false
  const checkmap = Object.keys(locationme).length === 0 || locationme == {} ? false : true
  const isValidForm = formik.isValid && formik.dirty && checkimg && checkmap
  const [checkchange, setcheckchange] = React.useState(false)

  const notiEdit = localStorage.getItem('notiEdit')

  const isSizeMobile = 768

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize)
  // }, [])

  // const handleResize = () => {
  //   setWindowWidth(window.innerWidth)
  // }

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 820) // ตัวอย่างเงื่อนไขขนาดหน้าจอ responsive
    }

    handleResize() // เรียกใช้งานเมื่อโหลดครั้งแรก
    window.addEventListener('resize', handleResize) // เพิ่ม event listener สำหรับ resize

    return () => {
      window.removeEventListener('resize', handleResize) // ตรวจจับเหตุการณ์การ resize
    }
  }, [])

  // useEffect(() => {
  //   if (windowWidth <= isSizeMobile) {
  //     setOpen(false)
  //     setMobile(true)
  //   } else {
  //     setOpen(true)
  //     setMobile(false)
  //   }
  // }, [windowWidth])

  useEffect(() => {
    getMasterData()
    localStorage.removeItem('search')
    const checkNoti = () => {
      if (notiEdit) {
        openNotificationEditSuccess()
        localStorage.removeItem('notiEdit')
      }
    }
    checkNoti()
  }, [])

  useEffect(() => {
    token ? getAllDataComplant() : getComplantGuest()
    token ? getAllDataEservice() : getEserviceGuest()
    setBoxheight(1)
  }, [sharedValue])

  const onclearinput = async () => {
    formik.resetForm()
    // setcheckchange(true)
    setDefaultValueFromList({
      first_name: mockUserData ? '' : '',
      last_name: mockUserData ? '' : '',
      mobile_number: mockUserData ? '' : '',
      groupuser: mockUserData ? '' : '',
      address: mockUserData ? '' : '',
      province: mockUserData ? '' : '',
      district: mockUserData ? '' : '',
      sub_districte: mockUserData ? '' : '',
      postcode: mockUserData ? '' : '',
      categories: mockUserData ? '' : '',
      name_complain: mockUserData ? '' : '',
      description_complain: mockUserData ? '' : '',
    })
  }

  const getMasterData = async () => {
    token ? getAllDataComplant() : getComplantGuest()
    token ? getAllDataEservice() : getEserviceGuest()
    // setTimeout(() => {
    setLoadingComplaintMe(false)
    // }, 1000)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const getAllDataComplant = async () => {
    const res = await getList({
      schema: '/complaint/api/v1/complaint',
      params: {
        filters: [meData?.id ? `created_by:eq:${meData?.id}` : undefined],
        search: [
          sharedValue ? `mobile_number:${sharedValue}` : undefined,
          sharedValue ? `complaint_no:${sharedValue}` : undefined,
        ],
      },
    })
    if (res?.code === 200) {
      if (res?.dataLength > 0) {
        setDataMap(res?.items)
      }
      // setTimeout(() => {
      //   setDataMap(res?.items)
      //   // setZoom(14)
      // }, 500)
    } else {
      setDataMap([])
    }
  }

  const getAllDataEservice = async () => {
    const res = await getList({
      schema: '/complaint/api/v1/e-service/location/list',
      params: {
        filters: [meData?.id ? `created_by:eq:${meData?.id}` : undefined],
        search: [
          sharedValue ? `mobile_number:${sharedValue}` : undefined,
          sharedValue ? `complaint_no:${sharedValue}` : undefined,
        ],
      },
    })
    if (res?.code === 200) {
      if (res?.dataLength > 0) {
        setEserviceData(res?.items)
      }
      // setTimeout(() => {
      //   setEserviceData(res?.items)
      //   // setZoom(14)
      // }, 500)
    } else {
      setEserviceData([])
    }
  }

  const getComplantGuest = async () => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/guest/all/complaint`, {
        params: {
          filters: [],
          search: [
            sharedValue ? `mobile_number:${sharedValue}` : undefined,
            sharedValue ? `complaint_no:${sharedValue}` : undefined,
          ],
        },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then(res => {
        // setTimeout(() => {
        if (res?.data?.code === 200 || 201) {
          if (res?.data?.data?.length > 0) {
            setDataMap(res?.data?.data)
          }
        } else {
          setDataMap([])
        }
        // setZoom(14)
        // }, 500)
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const getEserviceGuest = async () => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/guest/all/eservice`, {
        params: {
          filters: [],
          search: [
            sharedValue ? `mobile_number:${sharedValue}` : undefined,
            sharedValue ? `complaint_no:${sharedValue}` : undefined,
          ],
        },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then(res => {
        // setTimeout(() => {
        if (res?.data?.code === 200 || 201) {
          if (res?.data?.data?.length > 0) {
            setEserviceData(res?.data?.data)
          }
        } else {
          setEserviceData([])
        }
        // setZoom(14)
        // }, 500)
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const covertTothai = async position => {
    try {
      const apiKey = 'AIzaSyBOrbrRCU9ZyF1FxHyBkJwuBMGA7vtZa14'
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}&language=th`
      )
      const data = await response.json()

      if (data.status === 'OK' && data.results.length > 0) {
        // setLocationName(data.results[0].formatted_address)
      }
    } catch (error) {
      console.error('Error fetching location name:', error)
    }
  }

  const getProvince = async () => {
    const res = await getData({
      schema: `${masterdata}/all/province`,
      params: {},
      url: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '19' ? -1 : b.id === '19' ? 1 : 0))

      setProvinceData(newData)
    }
  }
  const getDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/district/` + provinceId,
      params: {},
      url: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '215' ? -1 : b.id === '215' ? 1 : 0))

      setDistrictData(newData)
    }
  }

  const getSubDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/sub-district/` + districtId,
      params: {},
      url: '',
    })
    // setSubDistrictData(res?.data)

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '300101' ? -1 : b.id === '300101' ? 1 : 0))

      setSubDistrictData(newData)
    }
  }

  const getCategories = async () => {
    const res = await getData({
      schema: `${masterdata}/all/categories`,
      params: {},
      url: '',
    })
    // setSubDistrictData(res?.data)
    if (res) {
      const newData = res.data
      setCategories(newData)
    }
  }
  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  // useEffect(() => {
  //   provinceId && getDistrict()
  // }, [provinceId])

  // useEffect(() => {
  //   districtId && getSubDistrict()
  // }, [districtId])

  const optionsProvince = provinceData?.map((items, index) => ({
    value: items?.id,
    label: items?.province_name.th,
    key: index,
  }))

  const optionsCategories = categories?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    key: index,
  }))

  const optionsDistrict = districtData?.map((items, index) => ({
    value: items?.id,
    label: items?.district_name.th,
    key: index,
  }))

  const optionsSubdistrict = subDistrictData?.map((items, index) => ({
    value: items?.id,
    label: items?.sub_district_name.th,
    key: index,
  }))

  const DatetimeNowThai = () => {
    const currentDate = new Date()

    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      eraDisplay: 'narrow',
    }

    const thaiLocale = 'th-TH'

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat(thaiLocale, options).format(currentDate)
    setFormattedDate(formattedDate)
  }

  useEffect(() => {
    DatetimeNowThai()
  }, [])

  React.useEffect(() => {
    formik.setFieldValue('cover', cover)
  }, [cover])

  const onclickComplain = () => {
    // setsubmit({ status: 1 })
    // setOpenDrawerDetailComplaint(true)
    navigate('/create-complaint')
  }
  const oncloseComplain = () => {
    setsubmit({ status: 1 })
    setOpenDrawerDetailComplaint(false)
  }

  // const openNotification = placement => {
  //   api.info({
  //     message: 'เข้าสู่ระบบสำเร็จ',
  //     placement,
  //     icon: <IconSurprised />,
  //     closeIcon: <IconClose />,
  //     duration: 3,
  //   })
  // }

  const openNotificationEditSuccess = typeNoti => {
    notification.info({
      message: <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>แก้ไขรายละเอียดสำเร็จ</div>,
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 3,
    })
  }

  const getMe = async () => {
    const res = await getData({
      schema: `${authentication}/me`,
    })

    if (res) {
      if (res?.code === 200) {
      } else {
        console.log('err')
      }
    }
  }
  const uniqueValidate = value => {
    if (value?.mobile_number?.length < 12) {
      setTimeout(() => {
        formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
      }, 100)
    }
    if (formik.values.mobile_number.length < 12) {
      formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
    }
  }

  const onChangeProvince = () => {
    formik.setFieldValue('district_id', '')
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getDistrict()
    // getSubDistrict()
  }
  const onChangeDistrict = async value => {
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getSubDistrict()
  }

  const handleDrawerOptionClick = (value, fieldFormik) => {
    if (fieldFormik === 'group_id') {
      formik.setFieldValue('group_id', value)
    }
    if (fieldFormik === 'province_id') {
      formik.setFieldValue('province_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('district_id', '')
      formik.setFieldValue('sub_district_id', '')
      setProvinceId(value)
    }
    if (fieldFormik === 'district_id') {
      formik.setFieldValue('district_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('sub_district_id', '')
      setDistrictId(value)
    }
    if (fieldFormik === 'sub_district_id') {
      formik.setFieldValue('sub_district_id', value)
      setSubDistrictId(value)
    }
  }

  const showDetailIfClickNoti = notiValue => {
    !isResponsive && onClickShowDetail(notiValue)
    isResponsive && onResponsiveClickShowDetail(notiValue)
  }

  useEffect(() => {
    notiValue !== '' && showDetailIfClickNoti(notiValue)
  }, [notiValue])

  const onClickShowDetail = data => {
    setShowDetail(true)
    setDataOnClickCard(data)
  }

  const onResponsiveClickShowDetail = data => {
    onOpenDrawerDetailComplaint()
    setDataOnClickCard(data)
  }

  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'เรื่องร้องเรียน') {
      if (token) {
        navigate('/create-complaint')
      } else {
        navigate('/create-complaint-guest')
      }
    } else if (e.key === 'บริการสาธารณะ') {
      if (token) {
        navigate('/create-e-service')
      } else {
        navigate('/create-e-service-guest')
      }
    }
  }

  const menuImport = value => {
    // setrecord(value)
    return (
      <Menucustom onClick={handleMenuImportClick}>
        <Menucustom.Item key={`เรื่องร้องเรียน`}>
          {/* {<img src={IconInputForm} alt="see" style={{ marginRight: '5px' }} />} */}
          เรื่องร้องเรียน
        </Menucustom.Item>
        <Menucustom.Item key={`บริการสาธารณะ`}>
          {/* {<img src={IconImportFile} alt="edit" style={{ marginRight: '5px' }} />} */}
          บริการสาธารณะ
        </Menucustom.Item>
      </Menucustom>
    )
  }

  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }

  const onCloseSeeMoreDetail = () => {
    setShowDetail(false)
  }

  const mockData = {
    id: '1',
    complaint_no: '0502256700011',
    title_complaint: 'ดูดโคลนหน้าบ้าน1',
    description: 'ดูดโคลนหน้าบ้าน1',
    coordinates: {
      latitude: 14.98233703299874,
      longitude: 102.10832893748167,
    },
    type_of_complaint: {
      id: '1746938558460981249',
      name: 'บริการรถดูดโคลนเลน',
      icon_url: 'testIconMap',
      bg_icon_url: '',
      icon_line: 'testIconMap',
      bg_icon_line: 'testIconMap',
    },
    status_data: [
      {
        name: 'received',
        description: 'รับเรื่อง',
        staff_data: null,
        created_at: 1707125321,
        created_by: '1753353277405130752',
      },
    ],
    department_data: {
      id: '1730459515045801985',
      name: 'สำนักช่าง',
      officer_data: null,
    },
    address: '269 ถ. สุรนารายณ์ ตำบลในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
    media_url: ['testIconMap'],
    created_at: 1707125321,
    created_by: '1753353277405130752',
    updated_at: 1707125321,
    updated_by: '',
  }

  const generateMockData = () => {
    const mockDataList = []
    for (let i = 0; i < 500; i++) {
      const mockData = {
        id: i.toString(),
        complaint_no: `05022567000${i}`,
        title_complaint: 'ดูดโคลนหน้าบ้าน1',
        description: 'ดูดโคลนหน้าบ้าน1',
        coordinates: {
          latitude: 14.98233703299874,
          longitude: 102.10832893748167,
        },
        type_of_complaint: {
          id: '1746938558460981249',
          name: 'บริการรถดูดโคลนเลน',
          icon_url: 'testIconMap',
          bg_icon_url: '',
          icon_line: 'testIconMap',
          bg_icon_line: 'testIconMap',
        },
        status_data: [
          {
            name: 'received',
            description: 'รับเรื่อง',
            staff_data: null,
            created_at: 1707125321,
            created_by: '1753353277405130752',
          },
        ],
        department_data: {
          id: '1730459515045801985',
          name: 'สำนักช่าง',
          officer_data: null,
        },
        address: '269 ถ. สุรนารายณ์ ตำบลในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
        media_url: ['testIconMap'],
        created_at: 1707125321,
        created_by: '1753353277405130752',
        updated_at: 1707125321,
        updated_by: '',
      }
      mockDataList.push(mockData)
    }
    return mockDataList
  }

  const onCheckComplaint = e => {
    setCheckBoxComplaint(e.target.checked)
  }

  const onCheckEservice = e => {
    setCheckBoxEservice(e.target.checked)
  }

  const renderSearchNotFound = () => {
    return (
      <>
        <div style={{ width: isResponsive ? '100%' : '30%' }}>
          {loadingComplaintMe ? (
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
              <Spin />
            </div>
          ) : (
            <WrapBoxSearchNotFound>
              <BoxResultText>
                <ArrowSearch style={{ cursor: 'pointer' }} onClick={() => setSharedValue('')} />
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '16px',
                    color: '#150F0B',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                >
                  ไม่พบผลลัพธ์การค้นหา
                </span>
                <sapn style={{ fontWeight: '400', fontSize: '16px', color: '#150F0B' }}>“{sharedValue}”</sapn>
              </BoxResultText>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignItems: 'center',
                  height: '85%',
                  justifyContent: 'center',
                }}
              >
                <EmptySearch />
                <span>
                  ขออภัย เราไม่พบเรื่องร้องเรียน <br />
                  โปรดตรวจสอบให้แน่ใจ ว่ารหัสเรื่องร้องเรียน <br />
                  หรือหัวข้อเรื่องร้องเรียนนั้นถูกต้อง
                </span>
              </div>
            </WrapBoxSearchNotFound>
          )}
        </div>
      </>
    )
  }

  const rederNomal = () => {
    return (
      <>
        <div style={{ width: isResponsive ? '100%' : '30%' }}>
          {loadingComplaintMe ? (
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
              <Spin />
            </div>
          ) : (
            <WrapBoxComplaint>
              {dataMap?.length > 0 && checkBoxComplaint ? (
                <>
                  <div>เรื่องร้องเรียนของฉัน ({dataMap?.length})</div>
                  {dataMap?.map((items, index) => {
                    return (
                      <BoxComplaintMe
                        onClick={() => {
                          onClickShowDetail(items)
                          isResponsive && onResponsiveClickShowDetail(items)
                          setBoxheight(1)
                        }}
                        data={items}
                        type="Complaint"
                      />
                    )
                  })}
                </>
              ) : (
                ''
              )}
              {eserviceData?.length > 0 && checkBoxEservice ? (
                <>
                  <div style={{ marginTop: '1rem' }}>
                    <div>ขอรับบริการ ({eserviceData?.length})</div>
                    {eserviceData?.map((items, index) => {
                      return (
                        <BoxComplaintMe
                          onClick={() => {
                            onClickShowDetail(items)
                            onResponsiveClickShowDetail(items)
                            setBoxheight(1)
                          }}
                          data={items}
                          type="Eservice"
                        />
                      )
                    })}
                  </div>
                </>
              ) : (
                ''
              )}
            </WrapBoxComplaint>
          )}
        </div>
      </>
    )
  }

  const renderSearch = () => {
    return (
      <div style={{ width: isResponsive ? '100%' : '30%' }}>
        {loadingComplaintMe ? (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
            <Spin />
          </div>
        ) : (
          <WrapBoxComplaint>
            <BoxResultText>
              <ArrowSearch style={{ cursor: 'pointer' }} onClick={() => setSharedValue('')} />
              <span
                style={{ fontWeight: '500', fontSize: '16px', color: '#150F0B', marginLeft: '8px', marginRight: '8px' }}
              >
                {dataMap?.length + eserviceData.length} ผลลัพธ์การค้นหา
              </span>
              <sapn style={{ fontWeight: '400', fontSize: '16px', color: '#150F0B' }}>“{sharedValue}”</sapn>
            </BoxResultText>
            {dataMap?.length > 0 && checkBoxComplaint ? (
              <>
                <div>เรื่องร้องเรียนของฉัน ({dataMap?.length})</div>
                {dataMap?.map((items, index) => {
                  return <BoxComplaintMe onClick={() => onClickShowDetail(items)} data={items} type="Complaint" />
                })}
              </>
            ) : (
              ''
            )}
            {eserviceData?.length > 0 && checkBoxEservice ? (
              <>
                <div style={{ marginTop: '1rem' }}>
                  <div>ขอรับบริการ ({eserviceData?.length})</div>
                  {eserviceData?.map((items, index) => {
                    return <BoxComplaintMe onClick={() => onClickShowDetail(items)} data={items} type="Eservice" />
                  })}
                </div>
              </>
            ) : (
              ''
            )}
          </WrapBoxComplaint>
        )}
      </div>
    )
  }

  const onOpenDrawerDetailComplaint = () => {
    setOpenDrawerDetailComplaint(true)
  }

  const onCloseDerwerDeaitlComplaint = () => {
    setOpenDrawerDetailComplaint(false)
  }

  return (
    <>
      <NotiStyle />
      {contextHolder}
      {loading ? (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
          <Spin />
        </div>
      ) : (
        <Container boxheight={boxheight}>
          <BoxTitle>
            {!isResponsive ? (
              <div style={{ marginRight: '15rem' }}>
                <CustomCheckBox style={{ marginRight: '1rem' }} checked={checkBoxComplaint} onChange={onCheckComplaint}>
                  แสดงเรื่องร้องเรียน
                </CustomCheckBox>
                <CustomCheckBox checked={checkBoxEservice} onChange={onCheckEservice}>
                  แสดงเรื่องบริการสาธารณะ(E-service)
                </CustomCheckBox>
              </div>
            ) : (
              <div>แผนที่ภาพรวมการร้องเรียน</div>
            )}
            <Dropdown
              overlay={() => menuImport()}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <BtNewComplaint>
                <span className="text">แจ้งเรื่องใหม่</span>
                <img
                  src={IconArrowDown}
                  alt="add"
                  style={{
                    display: 'inline',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </BtNewComplaint>
            </Dropdown>
          </BoxTitle>
          <WrapContent>
            {sharedValue && (dataMap.length > 0 || eserviceData.length > 0)
              ? renderSearch()
              : sharedValue && dataMap.length < 1 && eserviceData.length < 1
              ? renderSearchNotFound()
              : (dataMap.length > 0 || eserviceData.length > 0) && (checkBoxComplaint || checkBoxEservice)
              ? rederNomal()
              : ''}
            <div
              style={{
                width:
                  checkBoxComplaint || checkBoxEservice
                    ? dataMap?.length > 0 || eserviceData?.length > 0
                      ? '70%'
                      : '100%'
                    : '100%',
              }}
            >
              <div style={{ backgroundColor: 'blue', height: '100%' }}>
                <BoxContentDetail showDetail={showDetail}>
                  <WrapMap>
                    <MapHome
                      responsive={isResponsive}
                      onClickShowDetail={onClickShowDetail}
                      onResponsiveClickShowDetail={onResponsiveClickShowDetail}
                      eserviceData={eserviceData}
                      dataMap={dataMap}
                      checkBoxComplaint={checkBoxComplaint}
                      checkBoxEservice={checkBoxEservice}
                    />
                  </WrapMap>
                  {showDetail && (
                    <BoxSeeMoreDetail
                      onCloseSeeMoreDetail={onCloseSeeMoreDetail}
                      responsive={false}
                      data={dataOnClickCard}
                      getAllDataComplant={getAllDataComplant}
                      getAllDataEservice={getAllDataEservice}
                    />
                  )}
                </BoxContentDetail>
              </div>
            </div>
          </WrapContent>

          <WrapContentResponsive>
            <div className="div-wrap-map">
              <WrapMap>
                <MapHome
                  responsive={isResponsive}
                  onClickShowDetail={onClickShowDetail}
                  onResponsiveClickShowDetail={onResponsiveClickShowDetail}
                  eserviceData={eserviceData}
                  dataMap={dataMap}
                  checkBoxComplaint={checkBoxComplaint}
                  checkBoxEservice={checkBoxEservice}
                />
              </WrapMap>
            </div>
          </WrapContentResponsive>

          <div id={'complaintme-responsive'}>
            <div className="top-line">
              <VectorDrawer
                style={{ cursor: 'pointer' }}
                onClick={() => setBoxheight(boxheight === 1 ? 2 : boxheight === 2 ? 3 : 1)}
              />
            </div>
            {sharedValue && (dataMap.length > 0 || eserviceData.length > 0)
              ? renderSearch()
              : sharedValue && dataMap.length < 1 && eserviceData.length < 1
              ? renderSearchNotFound()
              : (dataMap.length > 0 || eserviceData.length > 0) && (checkBoxComplaint || checkBoxEservice)
              ? rederNomal()
              : ''}
          </div>

          <DrawerSeemoreDetail
            onCloseDerwerDeaitlComplaint={onCloseDerwerDeaitlComplaint}
            openDrawerDetailComplaint={openDrawerDetailComplaint}
            data={dataOnClickCard}
            isResponsive={isResponsive}
          />
        </Container>
      )}
    </>
  )
}

export default HomePage
