import React, { useState, useEffect, useContext } from 'react'
import {
  BoxSeeDetail,
  WrapBoxDescription,
  BoxDetail,
  BoxTimeline,
  GridDetail,
  BoxLocation,
  GridCategory,
  BoxGridDetail,
  GridImage,
  ModalPreviewImage,
  ModelContent,
  BtEdit,
  BtEnding,
  WrapBoxBtEnding,
  CustomModal,
  BtConfirm,
  CustomDrawer,
  WrapBoxStar,
  BtConfirmRate,
  WrapBoxRateInDetail,
  BoxRating,
  TextRating,
  BoxRatingInDetail,
} from './BoxSeeMoreDetail.style'

import { Form, Rate, Timeline } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import TestIcon from 'assets/images/svg/tset-icon-category.svg'

import { ReactComponent as ButtonCloseSeeDeail } from 'assets/images/svg/button-close-see-detail.svg'
import { ReactComponent as IconLocationComMe } from 'assets/images/svg/icon-location-card-complaint-me.svg'
import { ReactComponent as IconClockMe } from 'assets/images/svg/icon-clock-card-complaint-me.svg'
import { ReactComponent as IconCategoryMe } from 'assets/images/svg/icon-category-card-complaint-me.svg'
import { ReactComponent as IconNumberMe } from 'assets/images/svg/icon-number-card-complaint-me.svg'
import { ReactComponent as IconClosePreview } from 'assets/images/svg/icon-close-preview-img.svg'
import { ReactComponent as DotGreen } from 'assets/images/svg/dot-timeline-green.svg'
import { ReactComponent as DotOrange } from 'assets/images/svg/dot-timeline-orange.svg'
import { ReactComponent as DotPurple } from 'assets/images/svg/dot-timeline-purple.svg'
import { ReactComponent as DotYellow } from 'assets/images/svg/dot-timeline-yellow.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-complain.svg'
import { ReactComponent as Star1 } from 'assets/images/svg/star-1.svg'
import { ReactComponent as Star2 } from 'assets/images/svg/star-2.svg'
import { ReactComponent as Star3 } from 'assets/images/svg/star-3.svg'
import { ReactComponent as Star4 } from 'assets/images/svg/star-4.svg'
import { ReactComponent as Star5 } from 'assets/images/svg/star-5.svg'
import {
  checkStatusText,
  checkColorStatusBg,
  checkColorStatusText,
  converttimedate,
  converttimedateDesktop,
} from './container'
import { useNavigate } from 'react-router-dom'
import UserContext from 'core/contexts/userContext'
import InputComponent from 'components/input/Input'
import { postData, putData } from 'core/action/collection'
import { complaint } from 'core/schemas'

const BoxSeeMoreDetail = ({ onCloseSeeMoreDetail, responsive, data, getAllDataComplant, getAllDataEservice }) => {
  const navigate = useNavigate()
  const { meData } = useContext(UserContext)

  const [previewImg, setPreviewImg] = useState(null)
  const [previewVdo, setPreviewVdo] = useState(null)
  const [OpenModalVdo, setOpenModalVdo] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [formAntd] = Form.useForm()
  const [star, setStar] = useState(5)
  const [typeDrawer, setTypeDrawer] = useState('')

  let namestatus
  let colorbgnamestatus
  let colortextnamestatus
  let createcomplaint
  let laststatustime
  let laststatus
  let timestatus
  let datestatus
  let dot
  const Dotcolor = val => {
    if (val === 'received') {
      return <DotPurple />
    }
    if (val === 'in progress') {
      return <DotYellow />
    }
    if (val === 'done') {
      return <DotGreen />
    }
    if (val === 'outside the area') {
      return <DotOrange />
    }
    if (val === 'tracking') {
      return <DotOrange />
    }
    if (val === 'over duedate') {
      return <DotOrange />
    }
    if (val === 'transfer') {
      return <DotOrange />
    }
  }

  if (data) {
    let formatdate = converttimedate(data?.created_at)
    createcomplaint = formatdate?.time + ' น, ' + formatdate?.date

    if (data?.status_data) {
      laststatus = data?.status_data[data?.status_data.length - 1]
      namestatus = checkStatusText(laststatus?.name)
      colorbgnamestatus = checkColorStatusBg(laststatus?.name)
      colortextnamestatus = checkColorStatusText(laststatus?.name)
      laststatustime = converttimedate(laststatus?.created_at)
      timestatus = laststatustime.time + ' น'
      datestatus = laststatustime.date
      dot = Dotcolor(laststatus?.name)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const validateDescription = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm?.description?.length) {
      if (valForm?.description?.length <= 255) {
        return Promise.resolve()
      } else if (valForm?.description?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      }
    }
  }

  const handleChangeForm = async () => {
    setTimeout(async () => {
      let valForm = formAntd.getFieldsValue()
      if (!valForm?.description?.length) {
        setDisableBtn(true)
      } else {
        setDisableBtn(false)
      }
    }, 100)
  }

  const updateStatusComplaint = id => {
    const body = {
      description: 'อยู่ระหว่างดำเนินการ',
      name: 'in progress',
    }

    const res = putData(`${complaint}`, id, body)

    if (res) {
      if (res?.code === 200 || 201) {
        handleCancel()
      } else {
        console.log('resErr', res)
      }
    }
  }

  const onHavenService = () => {
    let valForm = formAntd.getFieldsValue()

    const body = {
      complaint_id: data?.id,
      description: valForm?.description,
    }

    const res = postData(`${complaint}/have-not-received`, body)

    if (res) {
      if (res?.code === 201 || 200) {
        // updateStatusComplaint(data?.id)
        handleCancel()
        onCloseSeeMoreDetail()
        getAllDataComplant()
        getAllDataEservice()
      }
    } else {
      console.log('resErr', res)
    }
  }

  const onOpenDrawer = type => {
    setOpenDrawer(true)
    setTypeDrawer(type)
  }

  const onCloseDrawer = () => {
    setOpenDrawer(false)
    setStar(5)
    setTypeDrawer('')
    formAntd.setFieldValue('descriptionRating', '')
  }

  const onSubmitRate = () => {
    let valForm = formAntd.getFieldsValue()

    if (typeDrawer === 'complaint') {
      const body = {
        rating: star,
        suggestions: valForm?.descriptionRating,
      }

      const res = putData(`${complaint}/rating`, data?.id, body)

      if (res) {
        if (res?.code === 200 || 201) {
          onCloseSeeMoreDetail()
          getAllDataComplant()
          getAllDataEservice()
          onCloseDrawer()
        } else {
          console.log('resErr', res)
        }
      }
    } else {
      const body = {
        rating: star,
        suggestions: valForm?.descriptionRating,
      }

      const res = putData(`${complaint}/e-service/rating`, data?.id, body)

      if (res) {
        if (res?.code === 200 || 201) {
          onCloseSeeMoreDetail()
          getAllDataComplant()
          getAllDataEservice()
          onCloseDrawer()
        } else {
          console.log('resErr', res)
        }
      }
    }
  }

  return (
    <>
      <BoxSeeDetail>
        <div className="wrap-box-title">
          <div className="box-title">
            <div className="title">
              <img
                src={data?.type_of_complaint ? data?.type_of_complaint?.icon_url : data?.category?.icon_url}
                alt=""
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              />
              <div>
                {data?.title_complaint ? data?.title_complaint : data?.complaint_name ? data?.complaint_name : '-'}
              </div>
            </div>
            <div className="description">{data?.description ? data?.description : '-'}</div>
          </div>
          <div className="box-status">
            <div className="status" style={{ backgroundColor: colorbgnamestatus, color: colortextnamestatus }}>
              {namestatus}
            </div>
            {responsive == false && (
              <ButtonCloseSeeDeail style={{ cursor: 'pointer' }} onClick={() => onCloseSeeMoreDetail()} />
            )}
          </div>
        </div>
        <WrapBoxDescription>
          <BoxDetail>
            <BoxLocation>
              <div style={{ width: '5%' }}>
                <IconLocationComMe />
              </div>
              <div style={{ width: '100%' }}>
                <p className="title">สถานที่:</p>
                <p className="description">{data?.address ? data?.address : '-'}</p>
              </div>
            </BoxLocation>
            <GridCategory>
              <div>
                <BoxGridDetail>
                  <div style={{ width: '10%' }}>
                    <IconClockMe />
                  </div>
                  <div style={{ width: '100%' }}>
                    <p className="title">วันและเวลาแจ้งเรื่อง:</p>
                    <p className="description">{createcomplaint}</p>
                  </div>
                </BoxGridDetail>
              </div>
              <div>
                <BoxGridDetail>
                  <div style={{ width: '10%' }}>
                    <IconNumberMe />
                  </div>
                  <div style={{ width: '100%' }}>
                    <p className="title">เลขที่:</p>
                    <p className="description">{data?.complaint_no ? data?.complaint_no : '-'}</p>
                  </div>
                </BoxGridDetail>
              </div>
              <div>
                <BoxGridDetail>
                  <div style={{ width: '10%' }}>
                    <IconCategoryMe />
                  </div>
                  <div style={{ width: '100%' }}>
                    <p className="title">ประเภท:</p>
                    <p className="description">
                      {data?.category?.name
                        ? data?.category?.name
                        : data?.type_of_complaint?.name
                        ? data?.type_of_complaint?.name
                        : '-'}
                    </p>
                  </div>
                </BoxGridDetail>
              </div>
              <div>
                {data?.sub_category_data?.name && (
                  <BoxGridDetail>
                    <div style={{ width: '10%' }}>
                      <IconCategoryMe />
                    </div>
                    <div style={{ width: '100%' }}>
                      <p className="title">ประเภทย่อย:</p>
                      <p className="description">
                        {data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}
                      </p>
                    </div>
                  </BoxGridDetail>
                )}
              </div>
            </GridCategory>
            <GridImage>
              {data?.media_url?.map(item => {
                if (item.endsWith('.mp4')) {
                  return (
                    <div style={{ height: '100%', width: '100%' }}>
                      <div
                        className="show-image-div click"
                        onClick={() => {
                          setPreviewVdo(item)
                          setOpenModalVdo(true)
                        }}
                      >
                        <video style={{ width: '98px', height: 'auto', maxHeight: '56px', borderRadius: '8px' }}>
                          <source src={item} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div style={{ height: '100%', width: '100%' }}>
                      <img
                        src={item}
                        alt="img"
                        style={{ borderRadius: '8px' }}
                        onClick={() => {
                          setPreviewImg(item)
                        }}
                      />
                    </div>
                  )
                }
              })}

              {/* <div style={{ height: '100%', width: '100%' }}>
                <img
                  src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg"
                  alt="img"
                  style={{ borderRadius: '8px' }}
                  onClick={() => {
                    setPreviewImg('https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg')
                  }}
                />
              </div> */}

              {/* <div style={{ height: '100%', width: '100%' }}>
                <img
                  src="https://cdn.pixabay.com/photo/2023/05/25/07/38/antarctica-8016562_1280.jpg"
                  alt="img"
                  style={{ borderRadius: '8px' }}
                  onClick={() => {
                    setPreviewImg('https://cdn.pixabay.com/photo/2023/05/25/07/38/antarctica-8016562_1280.jpg')
                  }}
                />
              </div> */}
            </GridImage>
          </BoxDetail>
          {responsive == false && (
            <BoxTimeline>
              <div>
                <Timeline mode="left">
                  {data?.status_data?.map((items, index) => {
                    const formatTimeTimeline = converttimedateDesktop(items.created_at)

                    return (
                      <Timeline.Item label={formatTimeTimeline?.date} dot={Dotcolor(items?.name)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <div className="timeline-title">{checkStatusText(items?.name)}</div>
                            <div className="timeline-desription">
                              {items?.name === 'received'
                                ? 'กำลังประสานงาน ไปยังหน่วยงานที่เกี่ยวข้อง'
                                : items?.name === 'in progress'
                                ? 'หน่วยงานได้รับเรื่องแล้ว และกำลังรอการดำเนินการ'
                                : ''}
                            </div>
                          </div>
                          <div
                            className="timeline-timedate"
                            style={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#ABA9A8',
                              width: '40%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {formatTimeTimeline?.time + ' น.'}
                          </div>
                        </div>
                      </Timeline.Item>
                    )
                  })}
                </Timeline>
              </div>
              {data?.created_by === meData?.id && data?.complaint_name && data?.status_data?.length === 1 && (
                <BtEdit onClick={() => navigate(`/edit-complaint/${data?.id}`)}>แก้ไขรายละเอียด</BtEdit>
              )}
              {data?.created_by === meData?.id &&
                data?.rating === null &&
                data?.complaint_name &&
                data?.status_data[data?.status_data?.length - 1]?.name === 'done' && (
                  <>
                    <WrapBoxBtEnding>
                      <BtEnding onClick={() => showModal()}>ยังไม่ได้รับบริการ</BtEnding>
                      <BtEnding type="done" style={{ marginLeft: '8px' }} onClick={() => onOpenDrawer('complaint')}>
                        ร้องเรียนสำเร็จ
                      </BtEnding>
                    </WrapBoxBtEnding>
                  </>
                )}

              {data?.created_by === meData?.id &&
                data?.rating === null &&
                data?.title_complaint &&
                data?.status_data[data?.status_data?.length - 1]?.name === 'done' && (
                  <WrapBoxBtEnding>
                    <BtEnding type="done" style={{ width: 'auto' }} onClick={() => onOpenDrawer('eservice')}>
                      ประเมินความพึงพอใจ
                    </BtEnding>
                  </WrapBoxBtEnding>
                )}

              {data?.rating && (
                <WrapBoxRateInDetail>
                  <BoxRating>
                    <TextRating>ให้คะแนนความพึงพอใจ</TextRating>
                    <BoxRatingInDetail>
                      <Rate value={data?.rating} disabled />
                    </BoxRatingInDetail>
                  </BoxRating>
                </WrapBoxRateInDetail>
              )}
            </BoxTimeline>
          )}
          {responsive == true && (
            <BoxTimeline>
              <div>
                <Timeline mode="left">
                  <Timeline.Item label={datestatus} dot={dot}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <div className="timeline-title">{laststatus?.name}</div>
                        <div className="timeline-desription">{laststatus?.description}</div>
                      </div>
                      <div style={{ fontSize: '10px', fontWeight: '400', color: '#ABA9A8', width: '40%' }}>
                        {timestatus}
                      </div>
                    </div>
                  </Timeline.Item>
                  {/* <Timeline.Item label="2015-09-01 09:12:11">Solve initial network problems</Timeline.Item>
              <Timeline.Item label="2015-09-01 09:12:11">Network problems being solved</Timeline.Item> */}
                </Timeline>
              </div>
            </BoxTimeline>
          )}
        </WrapBoxDescription>
      </BoxSeeDetail>

      <div>
        <ModalPreviewImage
          title=""
          centered
          transitionName=""
          closable={false}
          open={previewImg}
          footer={false}
          onOk={() => {
            setPreviewImg(null)
          }}
          onCancel={() => {
            setPreviewImg(null)
          }}
        >
          <ModelContent>
            <IconClosePreview
              className="close-ui"
              onClick={() => {
                setPreviewImg(null)
              }}
            />
            {previewImg ? (
              <div className="vdo-content">
                <img src={previewImg} style={{ width: '100%', height: 'auto' }} alt="img" />
              </div>
            ) : null}
          </ModelContent>
        </ModalPreviewImage>
      </div>

      <div>
        <ModalPreviewImage
          title=""
          centered
          transitionName=""
          closable={false}
          open={OpenModalVdo}
          footer={false}
          onOk={() => {
            setOpenModalVdo(false)
          }}
          onCancel={() => {
            setOpenModalVdo(false)
          }}
        >
          <ModelContent>
            {/* <div
              className="close-ui"
              onClick={() => {
                setOpenModalVdo(false)
              }}
            ></div> */}
            <IconClosePreview
              className="close-ui"
              onClick={() => {
                setOpenModalVdo(false)
              }}
            />
            {previewVdo ? (
              <div className="vdo-content">
                <video width="750" height="500" controls>
                  <source
                    src={
                      'https://gateway.tgh-platform.com/sit/phk/homecare/media/videos/1fd01129-de59-4b93-b88c-1a78f403e211.mp4'
                    }
                    type="video/mp4"
                  />
                </video>
              </div>
            ) : null}
          </ModelContent>
        </ModalPreviewImage>
      </div>

      <CustomModal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} closable={false}>
        <div className="box-title">
          <div className="text-title">ยังไม่ได้รับบริการ ?</div>
          <div className="text-title-description">
            <span>
              กรุณาระบุเหตุผลที่ยังไม่ได้รับบริการ เพื่อส่งเรื่องให้แก่เจ้าหน้าที่ดำเนินการ
              <br /> และติดตามเรื่องร้องเรียนอีกครั้ง
            </span>
          </div>
        </div>
        <Form layout="vertical" form={formAntd} onValuesChange={handleChangeForm}>
          <Form.Item name="description" label={<div style={{ fontSize: '12px' }}>รายละเอียด</div>}>
            <TextArea autoSize={{ minRows: 5, maxRows: 5 }} placeholder="ระบุเหตุผล" />
          </Form.Item>
        </Form>
        <div style={{ width: '100%', display: 'flex', marginTop: '30px', justifyContent: 'center' }}>
          <BtConfirm onClick={() => handleCancel()}>ยกเลิก</BtConfirm>
          <BtConfirm
            type="confirm"
            style={{ marginLeft: '1rem' }}
            onClick={() => onHavenService()}
            disabled={disableBtn}
          >
            ยืนยัน
          </BtConfirm>
        </div>
      </CustomModal>

      <CustomDrawer
        placement="right"
        onClose={() => {
          onCloseDrawer()
        }}
        closable={false}
        open={openDrawer}
        closeIcon={<IconClear />}
        width={600}
      >
        <div style={{ height: '3.5em', position: 'relative', marginTop: '40px' }}>
          <div
            className="close-icon"
            onClick={() => {
              onCloseDrawer()
            }}
          >
            <IconClear />
          </div>
        </div>
        <div className="title">
          <h1>ประเมินความพึงพอใจ</h1>
        </div>
        <WrapBoxStar>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '160px' }}>
            <div style={{ marginRight: '72px' }}>
              {star === 1 ? (
                <Star1 />
              ) : star === 2 ? (
                <Star2 />
              ) : star === 3 ? (
                <Star3 />
              ) : star === 4 ? (
                <Star4 />
              ) : star === 5 ? (
                <Star5 />
              ) : (
                ''
              )}
            </div>
            <Rate value={star} onChange={e => setStar(e)} allowClear={false} tooltips={['1', '2', '3', '4', '5']} />
          </div>

          <div style={{ marginTop: '5rem' }}>
            <Form layout="vertical" form={formAntd} onValuesChange={handleChangeForm}>
              <Form.Item
                name="descriptionRating"
                rules={[{ validator: validateDescription }]}
                label={<div style={{ fontSize: '14px' }}>ระบุเหตุผลของคุณ</div>}
              >
                <TextArea autoSize={{ minRows: 5, maxRows: 5 }} placeholder="ระบุเหตุผลเพิ่มเติม (ถ้ามี)" />
              </Form.Item>
            </Form>
          </div>
          <div>
            <BtConfirmRate type="confirm" onClick={() => onSubmitRate()}>
              ส่งการประเมิน
            </BtConfirmRate>
          </div>
        </WrapBoxStar>
      </CustomDrawer>
    </>
  )
}

export default BoxSeeMoreDetail
